<template>
  <div>
    <div>
      <el-image fit="contain" :src="abouts" style="width:100%;" />
    </div>
    <el-breadcrumb separator="/" style="margin-left:10px; margin-top:10px">
      <el-breadcrumb-item :to="{ path: '/' }">{{
        $t('Nav.home')
      }}</el-breadcrumb-item>
      <el-breadcrumb-item> {{ $t('Nav.itc') }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="margin-top:20px; position: relative;">
      <el-image fit="contain" :src="ict" style="width:100%;" />
      <el-image
        fit="contain"
        :src="point"
        style="width:1rem;height:1rem; position: absolute; right:5rem;top:4rem;"
      />
      <el-image
        fit="contain"
        :src="point"
        style="width:1rem;height:1rem; position: absolute; right:5rem;top:4.5rem;"
      />
      <el-image
        fit="contain"
        :src="point"
        style="width:1rem;height:1rem; position: absolute; right:4.5rem;top:4.5rem;"
      />
      <el-image
        fit="contain"
        :src="point"
        style="width:1rem;height:1rem; position: absolute; right:13.5rem;top:2rem;"
      />
      <el-image
        fit="contain"
        :src="point"
        style="width:1rem;height:1rem; position: absolute; left:5.2rem;top:4.3rem;"
      />
      <el-image
        fit="contain"
        :src="point"
        style="width:1rem;height:1rem; position: absolute; left:19rem;top:6rem;"
      />
      <el-image
        fit="contain"
        :src="point"
        style="width:1rem;height:1rem; position: absolute; left:21.2rem;top:3.4rem;"
      />
    </div>
    <div style="margin-top:20px">
      <router-link :to="{ path: '/components/mobile/ict-1' }">
        <div style="position: relative;">
          <el-image
            fit="contain"
            :src="case01"
            style="width:100%;margin-bottom:20px"
          />
          <div
            style="width:100%;position:absolute; bottom:24px; height:30px; line-height:30px;background:#393c5b; color:#fff;"
          >
            {{ $t('Nav.itccase01_1') }}
          </div>
        </div>
      </router-link>
      <router-link :to="{ path: '/components/mobile/ict-2' }">
        <div style="position: relative;">
          <el-image
            fit="contain"
            :src="case02"
            style="width:100%;margin-bottom:20px"
          />
          <div
            style="width:100%;position:absolute; bottom:24px; height:30px; line-height:30px;background:#393c5b; color:#fff;"
          >
            {{ $t('Nav.itccase02_1') }}
          </div>
        </div>
      </router-link>
      <router-link :to="{ path: '/components/mobile/ict-3' }">
        <div style="position: relative;">
          <el-image
            fit="contain"
            :src="case03"
            style="width:100%;margin-bottom:20px"
          />
          <div
            style="width:100%;position:absolute; bottom:24px; height:30px; line-height:30px;background:#393c5b; color:#fff;"
          >
            {{ $t('Nav.itccase03_1') }}
          </div>
        </div>
      </router-link>
      <router-link :to="{ path: '/components/mobile/ict-4' }">
        <div style="position: relative;">
          <el-image
            fit="contain"
            :src="case04"
            style="width:100%;margin-bottom:20px"
          />
          <div
            style="width:100%;position:absolute; bottom:24px; height:30px; line-height:30px;background:#393c5b; color:#fff;"
          >
            {{ $t('Nav.itccase04_1') }}
          </div>
        </div>
      </router-link>
      <router-link :to="{ path: '/components/mobile/ict-5' }">
        <div style="position: relative;">
          <el-image
            fit="contain"
            :src="case05"
            style="width:100%;margin-bottom:20px"
          />
          <div
            style="width:100%;position:absolute; bottom:24px; height:30px; line-height:30px;background:#393c5b; color:#fff;"
          >
            {{ $t('Nav.itccase05_1') }}
          </div>
        </div>
      </router-link>
      <router-link :to="{ path: '/components/mobile/ict-6' }">
        <div style="position: relative;">
          <el-image
            fit="contain"
            :src="case06"
            style="width:100%;margin-bottom:20px"
          />
          <div
            style="width:100%;position:absolute; bottom:24px; height:30px; line-height:30px;background:#393c5b; color:#fff;"
          >
            {{ $t('Nav.itccase06_1') }}
          </div>
        </div>
      </router-link>
      <router-link :to="{ path: '/components/mobile/ict-7' }">
        <div style="position: relative;">
          <el-image
            fit="contain"
            :src="case07"
            style="width:100%;margin-bottom:20px"
          />
          <div
            style="width:100%;position:absolute; bottom:24px; height:30px; line-height:30px;background:#393c5b; color:#fff;"
          >
            {{ $t('Nav.itccase07_1') }}
          </div>
        </div>
      </router-link>
    </div>
    <div style="clear: both; overflow: hidden;margin-bottom:20px;"></div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Lazyload } from 'vant'
Vue.use(Lazyload)
export default {
  name: 'itc',
  components: {
    // HelloWorld
  },
  data() {
    return {
      abouts: require('../../assets/index/04.jpg'),
      ict: require('../../assets/mobile/04-map@3x.png'),
      point: require('../../assets/point.gif'),
      case01: require('../../assets/itc/1.jpg'),
      case02: require('../../assets/itc/2.jpg'),
      case03: require('../../assets/itc/3.jpg'),
      case04: require('../../assets/itc/4.jpg'),
      case05: require('../../assets/itc/5.jpg'),
      case06: require('../../assets/itc/6.jpg'),
      case07: require('../../assets/itc/7.jpg'),
    }
  },
  methods: {},
}
</script>

<style></style>
